<template>
    <v-container>
      <v-row>
        <v-col cols="12" lg="5">
          <s-crud
            title="Seleccionar Requerimiento"
            search-input
            :filter="filterRequirement"
            :config="configUser"
            @save="save($event)"
            @rowSelected="rowSelected($event)"
          >
          <template v-slot:RqStatusName="{ row }">
            <v-chip
              x-small
              :color="
                row.ReqStatus == 1 ? 'success' : row.ReqStatus == 3 ? 'error' : row.ReqStatus == 4 ? 'info' : row.ReqStatus == 5 ? 'warning' : ''
              "
            >
              {{ row.RqStatusName }}
            </v-chip>
          </template>
          <template v-slot:filter>
            <v-container>
              <v-container>
                <v-row justify="center">
                  <v-col lg="6" class="s-col-form">
                    <s-date v-model="filterRequirement.BeginDate" label="Fecha Inicio" />
                  </v-col>
                  <v-col lg="6" class="s-col-form"> <s-date v-model="filterRequirement.EndDate" label="Fecha Fin"/></v-col>
                </v-row>
                <v-row justify="center">
                  <!-- <v-col lg="2" class="s-col-form"
                    ><s-select-definition clearable label="Estados" v-model="filterRequirement.ReqStatus" :def="1128" :dgr="28"
                  /></v-col> v-if="$fun.isAdmin()"-->
                    <v-col lg="6" class="s-col-form" >
                    <s-select-area v-model="filterRequirement.AreID" label="Área"/>
                    </v-col>
                    <v-col lg="6" class="s-col-form">
                      <s-select-definition
                        label="Tipo Requerimiento"
                        :def="1126"
                        v-model="filterRequirement.TypeRequirement"
                      ></s-select-definition>
                    </v-col>
                </v-row>

              </v-container>
            </v-container>
          </template>
            <!-- <template v-slot:options>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" small elevation="0" @click="copyAllow">
                    <v-icon color="default" x-small>
                      fas fa-copy
                    </v-icon>
                  </v-btn>
                </template>
                <span>Copiar a</span>
              </v-tooltip>
            </template> -->
          </s-crud>
        </v-col>
        <v-col cols="12" lg="7">
          <s-toolbar label="FLUJO DE REQUERIMIENTO"  excel style="background-color: grey; color: white;">

          </s-toolbar>
          <v-card elevation="0" >
            <v-col>
              <v-row justify="center">
                <v-col style="padding: 0px; margin-top: 17px">
                <s-toolbar
                  :label="'DETALLE DE REQUERIMIENTO: '+ numberRequirement"
                   style="background-color: #04abc5; color: white; font-weight: bold;"
                ></s-toolbar>
                <!-- TABLA DETALLE DE REQUERIMIENTO -->
                <v-data-table
                  dense
                  :items="itemsDetail"
                  single-select
                  v-model="itemsDetailSelect"
                  :headers="headersDetail"
                  item-key="DerID"
                  @click:row="rowClick"
                  
                >
                  <template v-slot:item.DerStatusName="{ item }">
                    <!-- <v-chip x-small :color="item.DerStatus == 9 ? 'error' : ''" v-if="item.DerStatus == 1 || item.DerStatus == 9">
                      {{ item.DerStatusName }}
                    </v-chip> -->
                    <v-btn class="mx-2" x-small :color="item.DerStatus == 9 ? 'error' : ''" @click="click($event, {item})" v-if="item.OrdID >0">
                      {{ item.DerStatusName }}
                    </v-btn>
                    <v-chip v-if="item.OrdID <1"  
                    x-small
                    :color="item.DerStatus == 1 ? 'success' : item.DerStatus == 9 ? 'error' : item.DerStatus == 2 ? 'info' : item.DerStatus == 7 ? 'warning' : 'warning'"
                    >
                      {{ item.DerStatusName }}
                    </v-chip>
                  </template>
                  
                  <template v-slot:item.OrdDate="{ item }">
                    {{ $fun.formatDateView(item.OrdDate) }}
                  </template>
                </v-data-table>
              </v-col>
              </v-row>
             
              <v-row justify="center" v-if="this.numberOrder.length > 0">
                <v-col style="padding: 0px; margin-top: 17px">
                <s-toolbar
                  :label="'N° DE ORDEN: '+this.numberOrder"
                   style="background-color: #04abc5; color: white; font-weight: bold;"
                ></s-toolbar>
                <!-- TABLA DETALLE DE REQUERIMIENTO -->
                <v-data-table
                  dense
                  :items="itemsOrderDetail"
                  v-model="itemsOrderDSelect"
                  :headers="headersOrders"
                  item-key="OrdID"

                >
                </v-data-table>
              </v-col>
              </v-row>

              <v-row justify="center" v-if="this.numberOrderOpo> 0">
                <v-col style="padding: 0px; margin-top: 17px">
                <s-toolbar
                  :label="'N° DE PEDIDO: '+this.numberOrderOpo"
                   style="background-color: #f4c711; color: white; font-weight: bold;"
                ></s-toolbar>
                <!-- TABLA DETALLE DE REQUERIMIENTO -->
                <v-data-table
                  dense
                  :items="itemsOpoOrderFirst"
                  v-model="itemsOpoOrderFirst"
                  :headers="headersOpoOrders"
                  item-key="DocEntry"

                  
                >
                <template v-slot:item.StatusCancel="{ item }">
                    <v-chip x-small :color="item.StatusC == 'Activo' ? 'success' : 'error'" >
                      {{ item.StatusC }}
                    </v-chip>
                </template>
                <template v-slot:item.link="{ item }">
                    <v-btn class="mx-2" x-small  @click="orderView(item.OrdID)">
                      <v-icon x-small>far fa-eye </v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
              </v-row>
              
              <v-row justify="center" v-if="NumBill.length > 0">
                <v-col style="padding: 0px; margin-top: 17px">
                <s-toolbar
                  :label="'N° DE FACTURA: '+NumBill"
                   style="background-color: #f4c711; color: white; font-weight: bold;"
                ></s-toolbar>
                <!-- TABLA DETALLE DE REQUERIMIENTO -->
                <v-data-table
                  dense
                  :items="itemsBillFirst"
                  v-model="itemsBillFirst"
                  :headers="headersBill"
                  item-key="DocEntry"
                  
                >
                <template v-slot:item.StatuBill="{ item }">
                    <v-chip x-small :color="item.StatusBill == 'Vigente' ? 'success' : 'error'" v-if="item.StatusBill !== 'Vigente'" >
                      {{ item.StatusBill }}
                    </v-chip>
                    <v-btn class="mx-2" x-small :color="item.StatusBill == 'Vigente' ? 'success' : 'error'" @click="diagramView(item)" v-if="item.StatusBill == 'Vigente'" >
                      <v-icon x-small>far fa-eye </v-icon>  {{ item.StatusBill }} 
                    </v-btn>
                </template>
                <template v-slot:item.bView="{ item }">
                    <v-btn class="mx-2" x-small  @click="billView(item.OrdID)">
                      <v-icon x-small>far fa-eye </v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
              </v-row>

            </v-col>
          </v-card>
        </v-col>
    </v-row>
    <v-dialog v-model="dialogApproval" v-if="this.dialogApproval" width="50%">
      <ApprovalLevelsDocument :DocEntry="DocEntry" :TypeObject="hasOrdID ? 2 : 1" />
    </v-dialog>
    <v-dialog v-model="dialogOrder" max-width="1100px">
  <v-card>
    <v-card-title>
      <s-toolbar  
            color="#04abc5" 
            dark
            close
            @close="dialogOrder = false"
            :label="'Detalle del Pedido N° '+this.numberOrderOpo"
        >
        </s-toolbar>
    </v-card-title>
    <v-card-text>
      <!-- Aplicamos la clase CSS para bordes -->
      <v-simple-table class="table-bordered">
        <tbody>
          <tr v-for="(value, key) in orderDetails" :key="key">
            <td>{{ getFieldName(key) }}</td>
            <td>{{ value }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      
      <!-- Tabla Horizontal -->
      <v-simple-table style="margin-top: 10px;">
        <template v-slot:default>
          <thead class="table-bordered">
            <tr>
              <th style="background-color: #04abc5; color: white; font-weight: bold; ">Producto</th>
              <th style="background-color: #04abc5; color: white; font-weight: bold;">Centro de Costo</th>
              <th style="background-color: #04abc5; color: white; font-weight: bold;">Linea de Negocio</th> 
              <th style="background-color: #04abc5; color: white; font-weight: bold;">Cantidad</th>
              <th style="background-color: #04abc5; color: white; font-weight: bold;">Moneda</th>
              <th style="background-color: #04abc5; color: white; font-weight: bold;">SubTotal</th>
              <th style="background-color: #04abc5; color: white; font-weight: bold;">IGV</th>
              <th style="background-color: #04abc5; color: white; font-weight: bold;">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in itemsOpoOrderDet" :key="index">
              <td>{{ item.Dscription }}</td>
              <td>{{item.CENTERCOST}}</td>
              <td>{{ item.BUSINESSLINE }}</td>
              <td>{{ item.Quantity }}</td>
              <td>{{ item.DocCur }}</td>
              <td>{{ item.TotalFrgn }}</td>
              
            </tr>
            <tr v-for="(item, index) in itemsOpoOrderDet">
              <td v-if="index == itemsOpoOrderDet.length-1" colspan="4" style="background-color: #d0f4f4; color: white; font-weight: bold;"></td>
              <td v-if="index == itemsOpoOrderDet.length-1">{{ item.DocCur }}</td>
              <td v-if="index == itemsOpoOrderDet.length-1">{{ item.SubTotal }}</td>
              <td v-if="index == itemsOpoOrderDet.length-1">{{ item.IGV }}</td>
              <td v-if="index == itemsOpoOrderDet.length-1">{{ item.Total }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    
    </v-card-text>
    <!-- <v-card-actions>
      <v-btn color="error" @click="dialogOrder = false">Cerrar</v-btn>
    </v-card-actions> -->
  </v-card>
</v-dialog>
 <v-dialog v-model="dialogBill" max-width="1100px">
  <v-card>
    <v-card-title>
      <s-toolbar
            color="#04abc5" 
            dark
            close
            @close="dialogBill = false"
            :label="'Detalle de Factura N°: '+NumBill"
            ></s-toolbar>
    </v-card-title>  
    <v-card-text>      
      <v-simple-table class="table-bordered">
        <thead>
          <tr>            
            <th style="background-color: #04abc5; color: white; font-weight: bold;">Cod. Articulo</th>
            <th style="background-color: #04abc5; color: white; font-weight: bold;">Descripción</th>
            <th style="background-color: #04abc5; color: white; font-weight: bold;">Centro de Costo</th>
            <th style="background-color: #04abc5; color: white; font-weight: bold;">Linea de Negocio</th>
            <th style="background-color: #04abc5; color: white; font-weight: bold;">Cantidad</th>
            <th style="background-color: #04abc5; color: white; font-weight: bold;">Moneda</th>
            <th style="background-color: #04abc5; color: white; font-weight: bold;">SubTotal</th>
            <th style="background-color: #04abc5; color: white; font-weight: bold;">IGV</th>
            <th style="background-color: #04abc5; color: white; font-weight: bold;">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in itemsBill" :key="index">
            <td>{{ item.CodArticle }}</td>
            <td>{{ item.Description }}</td>
            <td>{{item.CENTERCOST}}</td>
            <td>{{ item.BUSINESSLINE }}</td>
            <td>{{ item.RequestedQuantity }}</td>
            <td>{{ item.DocCur }}</td>
            <td>{{item.DocCur=="US$"?item.TotalFrgn:item.LineTotal }}</td>
          </tr>
          <tr v-for="(item, index) in itemsBill">
              <td v-if="index == itemsBill.length-1" colspan="5" style="background-color: #d0f4f4; color: white; font-weight: bold;"></td>
              <td v-if="index == itemsBill.length-1">{{ item.DocCur }}</td>
              <td v-if="index == itemsBill.length-1">{{ item.SubTotal }}</td>
              <td v-if="index == itemsBill.length-1">{{ item.IGV }}</td>
              <td v-if="index == itemsBill.length-1">{{item.DocCur=="US$"?item.DocTotalSy:item.Total }}</td>
            </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>    
    <!-- <v-card-actions>
      <v-btn color="error" @click="dialogBill = false">Cerrar</v-btn>
    </v-card-actions> -->
  </v-card>
</v-dialog>     
<v-dialog v-model="showDialogDiagram" max-width="850px"  style=" height: 220px important!;  ">>
  <v-card>
    <v-card-title>
      <s-toolbar  
            color="#04abc5" 
            dark
            close
            @close="showDialogDiagram = false"
            :label="'Estado de Factura N°: '+NumBill"
        >
        </s-toolbar>
    </v-card-title>  
    <v-card-text>      
      <v-col>
              <v-row justify="center">
                <!-- Aquí se dibujará el diagrama de flujo -->
                <div id="diagramContainer" style="width: 100%; height: 220px; "></div>
              </v-row>
            </v-col>
    </v-card-text>    
  </v-card>
</v-dialog>

    </v-container>

</template>
<script>
import _sRequirementFChart from "../../../services/Logistics/RequirementFlowChartService";
import _sRequirementDetail from "../../../services/Logistics/RequirementDetailService";
import ApprovalLevelsDocument from "@/views/Security/Circuit/Levels/ApprovalLevelsDocument";
import * as d3 from 'd3';

export default {
    name: 'RequirementFlowChart',
    components: {  ApprovalLevelsDocument },
    data: () => ({
      filterRequirement: {
      TypeRequirement: 1,
      BeginDate:null,
      EndDate:null,
      AreID: 0
     },
     dialogBill:false,

     showDialogDiagram:false,
      itemsUsers: [],
      itemsPermit: [],
      orderDetails:{},
      item: null,
      numberRequirement: '',
      numberOrder: '',
      numberOrderOpo: 0,
      selectedPermit: [],
      showDialogCopy: false,
      usersCopy: [],
      userSelected: {},
      searchText: '',  
      itemsDetail: [],
      itemsOrderDetail: [],
      itemsOpoOrderDet: [],
      itemsOpoOrderFirst:[],
      itemsBillFirst:[],
      itemsBill:[],
      ServiceEdit: false,
      itemsDetailSelect: [],
      itemsOrderDSelect: [],
      itemsOpoOrderDSel: [],
      hasOrdID:false,
      dialogApproval:false,
      NumBill:'',
      fieldNames: {
        // DocEntry: 'ID',
        // DocNum: 'Número de Pedido',
        // StatusC: 'Estado',
        CodSupplier: 'Código del Proveedor',
        CardName: 'Nombre del Proveedor',
        RucClient: 'RUC del Proveedor',
        AddressClient: 'Direc. Proveedor',
        // NumberRequ: 'Número Requerimiento',
        DateReq: 'Fecha Requerimiento',
        DocDate: 'Fecha Contable',
        DateDelivery: 'Fecha de Entrega',
        // DocCur: 'Moneda',
        Comments: 'Comentario',
        // CENTERCOST: 'Centro de Costo',
        // BUSINESSLINE: 'Línea de Negocio'
      },
      DocEntry: null,
      dialogOrder: false,
      OrdID: null,
      configUser: {
        model: { 
          ReqID: "ID",
          RqStatusName: ""
        },
        headers: [
          { text: "ID", value: "ReqID" },
          { text: "N° Requerimiento", value: "ReqDocumentNumber" },
          { text: "Área", value: "AreName"},
          { text: "Creación", value: "CreationDate" },
        //   {
        //   text: "Estado",
        //   value: "RqStatusName",
        //   sortable: false,
        //   width: 50,
        //   align: "center",
        // },
        ],
        service: _sRequirementFChart,
      },
      headersDetail: [
      { text: "Descripción", value: "DerDescription", sortable: false },
      { text: "Catidad", value: "DerQuantity", sortable: false },
      { text: "Atención", value: "OrdDate", sortable: false },
      { text: "Linea de Negocio", value: "BslName", sortable: false },
      { text: "Centro de Costo", value: "CceName", sortable: false },
      // { text: "Orden", value: "OrdID", sortable: false },
      { text: "Estado", value: "DerStatusName", sortable: false },
    ],
    headersOrders: [
      { text: "ID", value: "OrdID", sortable: false  },
      { text: "N° Orden", value: "OrdDocumentNumber", sortable: false },
      { text: "Atención", value: "OrdDateS", sortable: false },
      { text: "Proveedor", value: "SupName", sortable: false },
      { text: "Periodo", value: "DocEntry", sortable: false },
    ],
    headersOpoOrders: [
      { text: "ID", value: "DocEntry", sortable: false  },
      { text: "N° Pedido", value: "DocNum", sortable: false },
      { text: "F.Contable", value: "accountingDate", sortable: false },
      { text: "Proveedor", value: "CardName", sortable: false },
      { text: "Ver Pedido", value: "link", sortable: false },
      { text: "Estado", value: "StatusCancel", sortable: false },
    ],
    headersBill: [
      { text: "ID", value: "DocEntry", sortable: false  },
      { text: "N° Factura", value: "NumBill", sortable: false },
      { text: "Proveedor", value: "Supplier", sortable: false },
      { text: "Ver Factura", value: "bView", sortable: false },
      { text: "Estado", value: "StatuBill", sortable: false },
    ],
    }),
    methods: {
      rowClic(item){
        
      },
      rowClicked(item){
        console.log("rowClicked",item);
        if (!item.DocEntry) {
          return;
        }else{
        _sRequirementFChart.OporDetailByDocEntry({ DocEntry: item.DocEntry }, this.$fun.getUserID()).then((r) => {
              this.itemsOpoOrderDet = r.data;
              this.itemsOpoOrderFirst = Array.isArray(r.data) && r.data.length > 0 ? [r.data[0]] : [];  // Asegurando que sea un array
              console.log("itemsOpoOrderDSel", r.data);
              console.log("itemsOpoOrderFirst", this.itemsOpoOrderFirst );
            });
        }
      },
      rowClick(item) {
        if (!item.OrdID) {
          this.numberOrder='';
          return; // Si OrdID es null o vacío, no hacemos nada
        }
         console.log("item",item );
         const sameOrdIDItems = this.itemsDetail.filter(i => i.OrdID === item.OrdID );
    
        // Si seleccionamos una fila con un OrdID diferente, eliminamos cualquier selección previa
        if (!sameOrdIDItems.every(i => this.itemsDetailSelect.includes(i))) {
          // Limpiamos la selección previa
          this.itemsDetailSelect = sameOrdIDItems;
        } else {
          // Si todas las filas del mismo OrdID están seleccionadas, desmarcamos todas
          this.itemsDetailSelect = [];
        }

        _sRequirementFChart.OrderdetalleByOpor({ OrdID: item.OrdID }, this.$fun.getUserID()).then((r) => {
              if (r.data.length > 0) {
                this.itemsOrderDetail = r.data;
              this.numberOrder=this.itemsOrderDetail[0].OrdDocumentNumber;
              this.numberOrderOpo=this.itemsOrderDetail[0].OrdDocNum;
              }else{
                this.numberOrder='';
                this.numberOrderOpo=0;
              }

              if(this.itemsOrderDetail[0].DocEntry>0){
                  _sRequirementFChart.OporDetailByDocEntry({ DocEntry: this.itemsOrderDetail[0].DocEntry }, this.$fun.getUserID()).then((x) => {
                    if (x.data.length > 0) {
                      this.itemsOpoOrderDet = x.data;
                      this.itemsOpoOrderFirst = Array.isArray(x.data) && x.data.length > 0 ? [x.data[0]] : [];  // Asegurando que sea un array
                    }
                    console.log("pedido,",x.data);  
                // console.log("itemsOpoOrderDSel", r.data);
                // console.log("itemsOpoOrderFirst", this.itemsOpoOrderFirst );
                 if(this.itemsOpoOrderFirst[0].DocNum>0){
                  _sRequirementFChart.LgsBillDetail_FlujoByDocNum({ DocNum: this.itemsOpoOrderFirst[0].DocNum }, this.$fun.getUserID()).then((n) => {    
                       console.log("fatura,",n.data);                   
                         if (n.data.length > 0) {
                          this.itemsBill=n.data;
                          this.itemsBillFirst= Array.isArray(n.data) && n.data.length > 0 ? [n.data[0]] : [];
                          this.NumBill=this.itemsBillFirst[0].NumBill;
                         }else{
                          this.NumBill='';
                         }
                    
                  });

                 }

            });
              }
              console.log("thisitemsOrderDetail",this.itemsOrderDetail);
            });
          
      },
      diagramView(item) {
  console.log("itemDoc", item.DatePay);
  // if (!item) return;

  // Limpiar el contenedor antes de dibujar un nuevo diagrama
  d3.select("#diagramContainer").selectAll("*").remove();

  let datPay = item.DatePay==null?'Sin fecha de pago': this.$fun.formatDateView(item.DatePay) ;
  let datAproval= item.DateApproval==null?'Sin fecha de aprobación': this.$fun.formatDateView(item.DateApproval) ;
  let datDocument= item.DocumentDate==null?'Sin fecha de documento': this.$fun.formatDateView(item.DocumentDate) ;
   
  // Definir los datos del diagrama con las fechas y estados
  const diagramData = [
    { label: "Fecha de Aprobación", value: datAproval },
    { label: "Fecha de Documento", value: datDocument },
    { label: "Fecha de Pago", value: datPay },
    { label: "Estado de Pago", value: item.StatusPay },
  ];

  // Configuración del diagrama
  const width = 800; // Ancho del diagrama
  const height = 220; // Alto del diagrama (aumentado para acomodar los rectángulos)
  const margin = { top: 45, right: 20, bottom: 20, left: 20 };

  // Usar $nextTick para asegurar que el DOM se haya renderizado antes de dibujar el diagrama
  this.$nextTick(() => {
    // Asegurarse de que el contenedor está disponible y visible
    this.showDialogDiagram = true;
    setTimeout(() => {
      // Crear el contenedor SVG para el diagrama
      const svg = d3.select("#diagramContainer")
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

        svg.append("text")
        .attr("x", width / 2 - margin.left) // Centrado horizontalmente
        .attr("y", -20) // Por encima del diagrama
        .attr("text-anchor", "middle")
        .attr("font-size", "20px")
        .attr("font-weight", "bold")
        .attr("fill", "#333")
        .text("Estado de Pago de Factura");
      // Crear un contenedor de rectángulos para cada estado
      const rectWidth = 150; // Ancho de cada rectángulo
      const rectHeight = 30; // Alto del rectángulo de título
      const spacing = 150; // Espacio entre rectángulos

      // Definir una escala para posicionar los rectángulos en el eje X
      const xScale = d3.scaleBand()
        .domain(diagramData.map((d, i) => i))
        .range([0, width - margin.left - margin.right])
        .padding(0.1);

      // Dibujar los rectángulos para los títulos (arriba)
      svg.selectAll("rect.title")
        .data(diagramData)
        .enter()
        .append("rect")
        .attr("x", (d, i) => xScale(i))
        .attr("y", 10) // Ubicación de los rectángulos de los títulos
        .attr("width", rectWidth)
        .attr("height", rectHeight)
        .attr("rx", 15) // Bordes redondeados
        .attr("ry", 15)
        .attr("fill", "#4CAF50");

      // Agregar texto dentro de los rectángulos de los títulos
      svg.selectAll("text.title")
        .data(diagramData)
        .enter()
        .append("text")
        .attr("x", (d, i) => xScale(i) + rectWidth / 2)
        .attr("y", 25) // Centrado verticalmente en el rectángulo de título
        .attr("dy", ".35em")
        .attr("text-anchor", "middle")
        .attr("fill", "white")
        .text(d => d.label);

      // Dibujar los rectángulos para los valores (debajo de los títulos)
      svg.selectAll("rect.value")
        .data(diagramData)
        .enter()
        .append("rect")
        .attr("x", (d, i) => xScale(i))
        .attr("y", 60) // Ubicación de los rectángulos de los valores
        .attr("width", rectWidth)
        .attr("height", 50) // Alto del rectángulo de valor
        .attr("rx", 15) // Bordes redondeados
        .attr("ry", 15)
        .attr("fill", "#eafa92");

      // Agregar texto dentro de los rectángulos de los valores
      svg.selectAll("text.value")
        .data(diagramData)
        .enter()
        .append("text")
        .attr("x", (d, i) => xScale(i) + rectWidth / 2)
        .attr("y", 90) // Centrado verticalmente en el rectángulo de valor
        .attr("dy", ".35em")
        .attr("text-anchor", "middle")
        .attr("fill", "black")
        .text(d => d.value);

      // Dibujar flechas entre los rectángulos (línea de tiempo)
      svg.selectAll("line")
        .data(diagramData.slice(0, -1)) // Excluir el último elemento
        .enter()
        .append("line")
        .attr("x1", (d, i) => xScale(i) + rectWidth)
        .attr("y1", 85) // Posición vertical de la flecha (en medio de los rectángulos)
        .attr("x2", (d, i) => xScale(i + 1))
        .attr("y2", 85) // Posición vertical de la flecha
        .attr("stroke", "#000")
        .attr("stroke-width", 2)
        .attr("marker-end", "url(#arrow)"); // Usamos un marcador de flecha

      // Definir el marcador de flecha
      svg.append("defs")
        .append("marker")
        .attr("id", "arrow")
        .attr("viewBox", "0 0 10 10")
        .attr("refX", 5)
        .attr("refY", 5)
        .attr("markerWidth", 4)
        .attr("markerHeight", 4)
        .attr("orient", "auto")
        .append("path")
        .attr("d", "M 0 0 L 10 5 L 0 10 Z")
        .attr("fill", "#000");

      this.showDialogDiagram = true;
    }, 100); // Agregar un retraso de 100ms para asegurarse de que el contenedor esté visible
  });
},


      orderView(item) {
    this.OrdID = item;  // Guardas el item seleccionado
    this.dialogOrder = true;  // Abrimos el modal

    // Seleccionamos los 20 campos que quieres mostrar
    const selectedFields = [
      'CodSupplier', 
      'CardName', 'RucClient','AddressClient', 'DateReq',
      'DocDate','DateDelivery', 'Comments'    ];

    // Asignamos solo los campos seleccionados a orderDetails
    const order = this.itemsOpoOrderDet[0];  // Supongo que es un array con un solo objeto
    this.orderDetails = {};

    // Agregamos los campos seleccionados a orderDetails
    selectedFields.forEach(field => {
      if (order.hasOwnProperty(field)) {
        this.orderDetails[field] = order[field];
      }
      });
    },
    billView(item){
      this.dialogBill = true;
    },
      // orderView(item) {
      //   this.OrdID = item;
      //   this.dialogOrder = true;
      // },
    getFieldName(field) {
       return this.fieldNames[field] || field;  // Si no hay un nombre personalizado, usamos la clave original
    },
    click(e, { item }) {
      if (item.OrdID != null) {
        this.hasOrdID = item.OrdID;
        this.dialogApproval = true;
        this.DocEntry = item.OrdID;
      } else {
        this.hasOrdID = null;
        this.dialogApproval = true;
        this.DocEntry = item.ReqID;
      }
    },
      rowSelected(event) {
        this.item = event; 
        if (event != null) {
        if (event.length > 0) {
          if (event[0].ReqID != null)
              this.numberRequirement=event[0].ReqDocumentNumber;
            _sRequirementDetail.detail({ ReqID: event[0].ReqID }, this.$fun.getUserID()).then((r) => {
              this.itemsDetail = r.data;
              this.numberOrder='';
              this.numberOrderOpo=0;
              this.NumBill='';
              console.log("thisitemsDetail",this.itemsDetail);
            });
        }
      }
      },
    }
}
</script>            

<style scoped>
.table-bordered tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table-bordered td {
  font-weight: normal;
}

.table-bordered th {
  font-weight: bold;
  background-color: #f9f9f9;
}
#diagramContainer {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
