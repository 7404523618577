import Service from "../Service";

const resource = "requireFlowChart/";

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    OrderdetalleByOpor(obj, requestID) {
        return Service.post(resource + "OrderdetalleByOpor", obj, {
            params: { requestID: requestID },
        });
    },
    OporDetailByDocEntry(obj, requestID) {
        return Service.post(resource + "OporDetailByDocEntry", obj, {
            params: { requestID: requestID },
        });
    },
    LgsBillDetail_FlujoByDocNum(obj, requestID) {
        return Service.post(resource + "BillDetailByDocNum", obj, {
            params: { requestID: requestID },
        });
    },


};